<template>
  <!-- 个人中心 -->
  <div class="personal no-image">
    <infoBackground></infoBackground>
    <div class="person-head">
      <div class="personal">
        <div class="avatar" v-if="userInfo">
          <i class="avatar-icon" :style="{ backgroundImage: 'url(' + userInfo.HeadPhotoPath + ')' }"></i>
          <p>{{ userInfo.NickName }}</p>
          <div class="vip-info" v-if="vipInfo">
            {{ vipInfo.name }}
            <span v-if="vipInfo.deadline">{{ vipInfo.deadline }}到期</span>
            <span v-if="vipInfo.isPastdue">(已过期)</span>
          </div>
        </div>
      </div>
      <div class="person-menu">
        <div class="menu">
          <span :class="{ selected: currentType == item.type }" v-for="item in menuList"
            @click="currentType = item.type">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="person-content" v-if="userInfo">
      <userInfo @refreshVIP="refreshVIP()" v-if="currentType == 'user-info'"></userInfo>
      <buyOrder v-if="currentType == 'buy-order'"></buyOrder>
      <achievement_list v-if="currentType == 'achieve'"></achievement_list>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import * as moment from "moment";

import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import infoBackground from "@/component/public/info-background/info-background.vue";

import userInfo from "@/component/personal/user-info/user-info.vue";
import buyOrder from "@/component/personal/buy-order/buy-order.vue";
import achievement_list from "@/component/personal/achievement_list/achievement_list.vue";

import {  VIP_PLATFORM } from '@/config/constant'

export default {
  components: {
    foot,
    navroot,
    infoBackground,
    userInfo,
    buyOrder,
    achievement_list
  },
  data() {
    return {
      userInfo: null,
      menuList: [
        { name: '个人信息', type: 'user-info' },
        { name: '已购订单', type: 'buy-order' },
        // { name: '徽章/证书', type: 'achieve' },
        // { name: '成绩/轨迹', type: 'portraits-info' }
      ],
      currentType: "user-info",

      studentVip: null,
      vipInfo: null,
    }
  },
  async mounted() {
    let { userInfo, studentVip } = this.$store.state;
    if (!userInfo) {
      return this.$router.push('/login');
    }

    this.userInfo = userInfo;
    this.studentVip = studentVip;
    this.findVip();
  },
  methods: {
    //找到vip
    findVip() {
      let vipInfo = this.studentVip;
      if (vipInfo) {
        if (vipInfo.deadline && new Date() > new Date(vipInfo.deadline)) vipInfo.isPastdue = true;
        vipInfo.deadline = vipInfo.deadline ? moment(vipInfo.deadline).format('YYYY-MM-DD') : null;
        this.vipInfo = vipInfo;
      }
    },
    //刷新vip
    async refreshVIP() {
      const studentVip = await this.$api.payorder.getStudentVip(this.userInfo.PK_UserGuid).then(res => res.data || null);
      if (!studentVip) return;
      const vips = studentVip.content ? JSON.parse(studentVip.content) : [];
      const vip = vips.find((item) => item.type == VIP_PLATFORM);
      if (vip) {
        vip.isPastdue = !vip.deadline || new Date() < new Date(item.deadline) ? false : true;
        this.$store.commit("studentVip", vip);
      }

      this.findVip();
    }
  }
}
</script>

<style scoped lang="scss">
@import "./personal.scss";
</style>