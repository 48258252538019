import axios from '../config'
import { lessonplay_data } from '../baseURL'
import { LESSONPLAY_TOKEN } from '../../config/url';

export default {
    getMessage(type, param) {
        let token = window.localStorage.getItem(LESSONPLAY_TOKEN);
        if (type == "verifyToken") token = param;
        return axios({
            url: lessonplay_data,
            method: "POST",
            data: {
                type,  						// 方法类型(必传)
                param, 					  	// 对应参数(必传)
                token						// 用户token(除两个登录方法外必传)
            }
        })
    },
    //验证token
    verifyToken(token) {
        return this.getMessage("verifyToken", token)
    },
    //注册
    register(param) {
        return this.getMessage("register", param)
    },
    //获取密保问题
    getProblemList() {
        return this.getMessage("getProblemList")
    },
    //获取我的密保问题
    getMyProblem() {
        return this.getMessage("getMyProblem")
    },
    //修改密保
    editSecurity(problemGuid, answer) {
        let param = { problemGuid, answer };
        return this.getMessage("editSecurity", param)
    },
    //检查密码是否正确
    checkPassword(password) {
        let param = { password };
        return this.getMessage("checkPassword", param)
    },
    //修改密码
    putPassword(password) {
        let param = { password };
        return this.getMessage("putPassword", param)
    },
    //修改学生信息
    updateStudent(param) {
        return this.getMessage("updateStudent", param)
    },
    //获取学生信息
    getStudent(param) {
        return this.getMessage("getStudent", param)
    },
    //查询学生所有记录
    getStudentRecord(param) {
        return this.getMessage("getStudentRecord", param)
    },
    //获取班级信息(包括章节及团队)
    getClassInfo(param) {
        return this.getMessage("classInfo", param)
    },
    //学生加入班级
    joinClass(param) {
        return this.getMessage("joinClass", param)
    },
    //搜索组织学校
    searchOrg(param) {
        return this.getMessage("searchOrg", param)
    },
    //获取班级成绩（完成度）
    getScoreList(param) {
        return this.getMessage("getScoreList", param)
    },
    //登录
    login(param) {
        return this.getMessage("login", param)
    },
    //根据邀请码进行查询班级
    classCode(param) {
        return this.getMessage("classCode", param)
    },
    //显示成就
    showAchievement(param) {
        return this.getMessage("showAchievement", param)
    },
    //获取所有成就
    getAllAchievement() {
        return this.getMessage("getAllAchievement")
    }
}



