<template>
  <div class="info-background">
    <img class="bak-top" src="@/assets/images/background/bak-info-bottom-right.png" alt />
    <img class="bak-bottom-left" src="@/assets/images/background/bak-info-bottom-left.png" alt />
    <img class="bak-bottom-right" src="@/assets/images/background/bak-info-bottom-right.png" alt />
  </div>
</template>
<script>
export default {
  name: 'info_background',
  data() {
    return {
      phoneMode: false,
    }
  },
  mounted() {
    const { phoneMode } = this.$store.state;
    this.phoneMode = phoneMode;
    if (phoneMode) return;

    // setTimeout(() => {
    //   let el = document.getElementById("content");
    //   el.style.background = '#ffffff00';
    // }, 1000);
  },
  beforeUnmount() {
    if (this.phoneMode) return;

    // let el = document.getElementById("content");
    // el.style.background = '#136BB4';
  },
}
</script>
<style scoped lang="scss">
@import "./info-background.scss";
</style>