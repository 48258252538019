<template>
  <div class="nav-box"  :class="{ desktop: $store.state.isElectron }">
    <div class="logo-box" @click="returnHome()">
      <!-- <svg class="svg-icon return-icon" @click="returnLast()" v-if="showReturn">
        <use xlink:href="#icon-last" />
      </svg> -->
      <img class="logo-icon" src="@/assets/images/yuanxi/nav-logo.png" alt />
    </div>
    <div class="menu-box" v-if="menuList.length > 1">
      <div class="menu-item" :class="{ selected: item.selected }" v-for="item of menuList"
        v-show="item.show" :key="item.name" @click="chooseMenu(item)">{{ item.name }}</div>
      <!-- <div class="menu-item" @click="routerAbout()">关于我们</div> -->
    </div>
    <div class="user-box">
      <div class="user-handler" v-if="$store.state.isElectron && $store.state.electronConfig.online"
        @click="routerDownload()">
        <svg class="icon-svg">
          <use xlink:href="#icon-xiazai1"></use>
        </svg>
      </div>
      <!-- <div class="user-handler">
        <a-tooltip title="桌面端">
          <svg class="icon-svg" @click="downApp()">
            <use xlink:href="#icon-application"></use>
          </svg>
        </a-tooltip>
        <a-tooltip title="手机端">
          <svg class="icon-svg" @click="showGuide = true">
            <use xlink:href="#icon-phone"></use>
          </svg>
        </a-tooltip>
      </div> -->
      <a-button class="login-button" type="primary" @click="openUrl('https://xjtupress.lessonplan.cn')">出版物编辑</a-button>
      <a-button class="login-button" type="primary" @click="loginIn" v-if="!userInfo">读者登录</a-button>
      <div class="logined" v-if="userInfo">
        <a-dropdown :trigger="['click']">
          <i class="avatar" :style="{ backgroundImage: 'url(' + userInfo?.HeadPhotoPath + ')' }"></i>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="routerPath(`/personal`)">
                <span>个人中心</span>
              </a-menu-item>
              <a-menu-item @click="logout()">
                <span>退出登录</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <div class="desktop-function" v-if="$store.state.isElectron">
        <svg @click="ipcRenderer.send('window:minimize')">
          <use xlink:href="#icon-zuixiaohua" />
        </svg>
        <svg @click="isMaxSize = !isMaxSize; ipcRenderer.send('window:maximize');">
          <use xlink:href="#icon-zuidahua" v-if="isMaxSize" />
          <use xlink:href="#icon-zuidahua1" v-if="!isMaxSize" />
        </svg>
        <svg class="icon-guanbi" @click="deskTopClose()">
          <use xlink:href="#icon-close-line" />
        </svg>
      </div>

    </div>

    <phoneGuide @closeBox="showGuide = false" v-if="showGuide"></phoneGuide>
  </div>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN, ONLINEURL } from '@/config/url';
import phoneGuide from './phone-guide/phone-guide.vue';

export default {
  name: "navroot",
  components: {
    phoneGuide
  },
  data() {
    return {
      userInfo: null,
      menuList: [{ name: "首页", url: "/home", selected: false, show: true }],
      showReturn: false,
      showGuide: false,
      isShowAbout: false,

      isMaxSize: true,
      ipcRenderer: null,

      desktopUrl: 'https://electronserve.lessonplan.cn/yuanxi/win64/元习 Setup 1.0.1.exe',
      device: ''
    }
  },
  computed: {
  },
  async mounted() {
    const { userInfo, device } = this.$store.state;
    if (userInfo) {
      this.userInfo = userInfo;
      this.menuList.push({ name: "我的数字出版物", url: "/mycourse", selected: false, show: true });
    }
    this.device = device;

    this.$store.subscribe((res) => {
      if (!res) return;
      let { type, payload } = res;
      if (type == "showReturn") this.showReturn = payload;
    })

    this.judgeMenu();
    this.routerChange();

    if (this.$route.name == 'home') this.isShowAbout = true;
  },
  methods: {
    //监听路由变化
    routerChange() {
      this.$router.afterEach((to, from) => {
        if (this.$route.name == 'home') this.isShowAbout = true;
        else this.isShowAbout = false;
        this.judgeMenu();
      })
    },
    setDownloadSrc() {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.send("dialog:open", "setDownloadSrc");
    },
    //判断当前菜单
    judgeMenu() {
      this.$nextTick(() => {
        this.menuList.forEach(menu => {
          if (menu.url != '/' && location.href.indexOf(menu.url) > -1) menu.selected = true;
          else menu.selected = false;

          menu.show = true;
        })
      })
    },
    //选择菜单
    chooseMenu(item) {
      this.menuList.forEach(menu => { menu.selected = false });
      item.selected = true;
      this.resetScroll();


      this.$router.push(item.url);
    },
    //退出
    logout() {
      this.$nzconfirm('确定要退出登录吗?', () => {
        let token = window.localStorage.getItem(LESSONPLAY_TOKEN);
        window.localStorage.removeItem(LESSONPLAY_TOKEN);
        if (this.$store.state.isElectron) {
          this.$router.push('/login');
        } else {
          location.href = `${PASSPORT_CLIENT_SERVER}/auth/logout?token=${token}&redirectURI=${ONLINEURL}&isTourist=true`;
        }
      })
    },
    //跳转至个人中心
    routerPath(url) {
      this.$router.push(url);
      this.menuList.forEach(menu => {
        menu.selected = false
      })
      this.resetScroll();
    },
    //返回
    returnLast() {
        this.$router.push('/home');
      this.resetScroll();
    },
    //返回首页
    returnHome() {
      this.$router.push('/home');
      this.resetScroll();
    },
    //重置滚动条
    resetScroll() {
      this.showReturn = false;
      $('#content').animate({ scrollTop: 0 }, 300);
    },

    //跳转登录
    loginIn() {
      this.$router.push('/login');
    },
    //下载桌面应用
    downApp() {
      this.$Modal.confirm({
        okText: '确定',
        cancelText: '取消',
        title: `确定要下载桌面应用吗`,
        content: '下载后，请在浏览器下载中心，查看下载进度',
        onOk: () => {
          this.openUrl(this.desktopUrl);
        }
      })
    },
    //打开新网页
    openUrl(url) {
      window.open(url);
    },
    //跳转我的下载
    routerDownload() {
      this.$router.push("/download");
    },

    //桌面退出
    deskTopClose() {
      this.$nzconfirm('确定要关闭窗口吗?', () => {
        this.ipcRenderer.send('window:close')
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "./nav.scss";
</style>