<template>
  <div class="empty-box">
    <img :src="empty_image" alt />
    <div>{{ warning }}</div>
  </div>
</template>
<script>
export default {
  name: 'foot',
  data() {
    return {
      empty_image: require('@/assets/images/empty.png'),
    }
  },
  props: {
    warning: String
  }
}
</script>
<style scoped lang="scss">
@import "./empty-box.scss";
</style>