<template>
  <div class="finish-box">
    <i class="finish-icon"></i>
    <div class="finish-title">支付成功</div>
    <div class="finish-notice">
      恭喜您已经完成支付，订单
      <span class="waite-time">{{ waitingTime }}s</span>后将自动跳转
    </div>
    <a-button size="large" class="finish-btn" @click="returnBack" type="primary">立即学习</a-button>
  </div>
</template>

<script>


export default {
  name: 'payfinish',
  components: {},
  data() {
    return {
      goodId: '',
      waitingTime: 10,
    }
  },
  mounted() {
    const { id } = this.$route.params;
    this.goodId = id;

    const that = this;
    const int = setInterval(() => {
      if (that.waitingTime <= 0) {
        clearInterval(int);
        that.returnBack();
      }
      that.waitingTime -= 1;
    }, 1000);

    //购买成功，更新购买状态
    const { storeBooks } = this.$store.state;
    storeBooks.forEach((item) => {
      if (item.pk_store_book_id === id) {
        item.isPaid = true;
      }
    })
    this.$store.commit("storeBooks", JSON.parse(JSON.stringify(storeBooks)));
  },
  methods: {
    //跳转
    returnBack() {
      this.$router.push({ path: `/course-info/${this.goodId}` })
    }
  }
}


</script>

<style scoped lang="scss">
@import "./pay-finish.scss";
</style>