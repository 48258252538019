<template>
  <a-config-provider :locale="locale" v-if="!loading">
    <!-- 页头 -->
    <navroot :class="{ 'phone-mode': phoneMode }" v-if="!hideNav"></navroot>
    <div id="content" :class="{ 'phone-mode': phoneMode, 'hideNav': hideNav }">
      <router-view />
    </div>
    <phoneBar v-if="phoneMode & !hideNav"></phoneBar>
  </a-config-provider>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN } from "./config/url";
import { STORE_ID, VIP_PLATFORM } from '@/config/constant'
import Vibrant from 'node-vibrant';

//汉化
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

import headPath from "@/filter/headPath";
import navroot from "@/component/public/nav/nav.vue";
import phoneBar from "@/component/public/phone-bar/phone-bar.vue";
import { notification } from "ant-design-vue";

export default {
  name: "App",
  data() {
    return {
      urlToken: "",
      token: "",

      storeBooks: [],

      loading: true,
      locale: zhCN,
      phoneMode: false,
      hideNav: false,
    };
  },
  computed: {},
  components: {
    navroot,
    phoneBar,
  },
  mounted() {
    //获取当前路由
    const { name } = this.$route;
    if (location.href.indexOf('bookReader') > -1 || location.href.indexOf('login') > -1 || name === 'bookReader') {
      this.hideNav = true;
    }
    if (name === 'bookReader') this.hideNav = true;

    this.urlToken = this.getURLQuery("token");
    this.$store.commit(
      "isElectron",
      !!(window && window.process && window.process.type)
    );
    this.judegeElectron();
    this.judgePhone();
    window.addEventListener("resize", this.judgePhone);
    document.addEventListener("UniAppJSBridgeReady", () => {
      uni.getEnv((res) => {
        if (res.plus) {
          window.getUserFormApp = async (res) => {
            const ret = await this.$api.passport.auth(JSON.parse(res));
            if (ret.status === 1) {
              this.userGuid = ret.userGuid;
              this.token = ret.token;
              window.localStorage.setItem(LESSONPLAY_TOKEN, this.token);
              this.deleteURLToken();
              this.loadFunction();
            }
          };
          uni.postMessage({
            data: {
              action: "getUser",
            },
          });
        }
      });
    });
  },
  methods: {
    //判断手机端
    judgePhone() {
      let w_width = window.innerWidth;
      let phoneMode = w_width < 700;
      if (this.phoneMode == phoneMode) return;

      this.phoneMode = phoneMode;
      this.$store.commit("phoneMode", this.phoneMode);
    },
    //检查是否在electron中
    async judegeElectron() {
      if (this.$store.state.isElectron) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.send("config:read");
        ipcRenderer.once("config:read", async (event, data) => {
          const electronConfig = data;
          this.$store.commit("electronConfig", data);
          if (electronConfig.online) {
            // 有网
            if (!electronConfig.token) {
              // this.judegeMiniProgram();
              this.loadFunction();
            } else {
              const ret = await this.$api.passport.auth({
                username: electronConfig.username,
                password: electronConfig.password,
              });
              if (ret.status === 1) {
                this.userGuid = ret.userGuid;
                this.token = ret.token;
                window.localStorage.setItem(LESSONPLAY_TOKEN, this.token);
                this.deleteURLToken();
                this.loadFunction();
              }
              return;
            }
          }
          if (!electronConfig.online) {
            this.loading = false;
            return;
          }
        });
        ipcRenderer.send("notification:read");
        ipcRenderer.on("notification:read", (event, data) => {
          for (let key in data) {
            if (!data[key].played) {
              notification.open({
                message: data[key].title,
                description: data[key].body,
              });
              data[key].played = true;
              ipcRenderer.send("notification:update", {
                key,
                data: data[key],
              });
            }
          }
        });
      } else {
        this.judegeMiniProgram();
      }
    },
    //检查是否在小程序中
    async judegeMiniProgram() {
      let isMiniProgram = await this.$api.passport.checkInMiniProgram();
      this.isMiniProgram = isMiniProgram;

      if (isMiniProgram && this.urlToken) {
        const ret = await this.$api.passport.verifyToken(this.urlToken);

        if (ret.status === 1) {
          this.userGuid = ret.userGuid;
          this.token = ret.token;

          window.localStorage.setItem(LESSONPLAY_TOKEN, this.urlToken);
          this.deleteURLToken();
          this.loadFunction();

          return;
        }
      }
      this.judgeToken();
    },
    //判断token
    async judgeToken() {
      //const token = process.env.NODE_ENV === 'development' ? 'development' : window.localStorage.getItem(LESSONPLAY_TOKEN);
      const token = window.localStorage.getItem(LESSONPLAY_TOKEN);
      let herfurl = location.href.split("?")[0];
      //localStorage中不存在token时，先尝试从URL中获取token
      if (!token) {
        //URL中也不存在token，跳转到认证中心检查（其他应用）是否已经登录过
        if (!this.urlToken)
          location.href = `${PASSPORT_CLIENT_SERVER}/auth/checkTouristLogin?redirectURI=${herfurl}`;
        //URL中存在token，验证该token的有效性（这种情况一般发生在首次登录）
        else {
          //游客登录
          if (this.urlToken === "tourist") {
            return this.loadFunction();
          }

          const ret = await this.$api.passport.verifyToken(this.urlToken);
          //URL中的token有效，将该token写入localStorage并删除URL中的token
          if (ret.status === 1) {
            window.localStorage.setItem(LESSONPLAY_TOKEN, this.urlToken);

            this.userGuid = ret.userGuid;
            this.token = ret.token;
          }
          //URL中的token无效，删除URL中的token并跳转到登录页（这种情况一般是用户手动在URL后面添加了token参数）
          else this.deleteURLToken();
        }
      }

      //localStorage中存在token时，验证该token的有效性
      else {
        const ret = await this.$api.passport.verifyToken(token);
        //localStorage中的token有效
        if (ret.status === 1) {
          this.userGuid = ret.userGuid;
          this.token = ret.token;
        }

        //localStorage中的token无效，清除该token并跳转到认证中心检查（其他应用）是否已经登录过
        else window.localStorage.removeItem(LESSONPLAY_TOKEN);
      }
      this.loadFunction();
    },
    //删除URL中的token
    deleteURLToken() {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('token')
      const newURL = searchParams.toString() ? `${location.pathname}?${searchParams.toString()} ` : location.pathname
      history.pushState({}, '', newURL)
    },
    //获取URL中的值
    getURLQuery(query) {
      const search = new URLSearchParams(location.search);
      const value = search.get(query);
      return value;
    },


    //加载方法
    async loadFunction() {
      const currentStore = await this.$api.bookManage.storeFindOne().then((res) => res.data || []);
      this.$store.commit("setCurrentStore", currentStore);

      //获取书店图书
      const storeBooks = await this.$api.bookManage.storeBookFind({ storeId: STORE_ID }).then((res) => res.data || []);
      this.storeBooks = storeBooks;
      this.$store.commit("setStoreBooks", storeBooks);

      this.deleteURLToken();
      this.getUserInfo();
    },
    //获取用户信息
    async getUserInfo() {
      const userGuid = this.userGuid;
      if (!userGuid) return (this.loading = false);

      let bookUser = null;
      const userInfo = await this.$api.unifydata.getStudent({ userGuid }).then((res) => res.data);
      if (userInfo) {
        userInfo.NickName = userInfo.NickName || userInfo.Name;
        userInfo.token = this.token;
        userInfo.HeadPhotoPath = headPath(userInfo.HeadPhotoPath);

        //更新用户信息
        bookUser = await this.$api.bookManage.bookUserUpdate({
          fk_user_id: userGuid,
          account: userInfo.Name,
          name: userInfo.NickName || userInfo.Name,
          avatar: userInfo.HeadPhotoPath || ''
        }).then((res) => res.data || null);
      }
      this.$store.commit("userInfo", userInfo);

      //获取我的购买订单
      const orderList = await this.$api.payorder.getOrderList(userGuid).then((res) => res.data || []);;
      this.storeBooks.forEach(book => {
        Vibrant.from(book.extension_info.cover).getPalette().then((palette) => {
          let primaryColor = palette.DarkVibrant.hex;
          book.primaryColor = `${primaryColor}ba`;
        })

        const order = orderList.find((oitem) => oitem.system_id === book.system_id);
        if (order) book.isPaid = order.isPaid;

        if (bookUser?.join_class) {
          const { join_class } = bookUser;
          const joinClass = join_class.find((item) => item.fk_publishing_id === book.fk_publishing_id);
          if (joinClass) {
            book.fk_book_class_id = joinClass.fk_book_class_id;
          }
        }
      })

      this.$store.commit("setStoreBooks", this.storeBooks);

      //获取vip身份
      const studentVip = await this.$api.payorder.getStudentVip(userGuid).then((res) => res.data || null);
      if (studentVip) {
        const vips = studentVip.content ? JSON.parse(studentVip.content) : [];
        const vip = vips.find((item) => item.type == VIP_PLATFORM);
        if (vip) {
          vip.isPastdue = !vip.deadline || new Date() < new Date(item.deadline) ? false : true;
          this.$store.commit("studentVip", vip);
        }
      }

      this.loading = false;
    }
  }
};
</script>

<style></style>
