import passport from './interfaces/passport';
import unifydata from './interfaces/unifydata';

import accounts from './interfaces/accounts';
import payorder from './interfaces/payorder';
import download from './interfaces/download';
import bookManage from './interfaces/bookManage';

export default {
    passport,
    unifydata,
    accounts,
    payorder,
    download,
    bookManage
}